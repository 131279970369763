import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'S3 Cloud Storage Pricing';
const content = {
  hero: {
    title: "One-stop shop for",
    emphasized: "S3 Cloud Storage Pricing.",
    subtitle: "There is a better way to shop for AWS cloud storage.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"aws",
    title: "Save 60% or more on AWS cloud storage pricing from leading partners",  
    subtitle: "Let managed service providers bid for your business",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "s3-cloud-storage-pricing/2",   
  },
  subText: {
    title: "S3 Storage Shopping",
    emphasized: "Simplified.",
    subtitle: "Your time is important, we can help you reduce S3 costs by providing you with competitive quotes from Managed Service Providers and AWS partners which are up to 70% lower than AWS pricing. Request a quote for AWS storage today or let us help you with our AWS pricing calculator.",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 